import { useState } from "react";
import { format } from "date-fns";
import { ReportGeneratorFormWithMonth } from "../../../pages";
import { BeatLoader } from "react-spinners";
import { AiOutlineSearch } from "react-icons/ai";
import { GET_DEBT_COLLECTION_CANCELLATION_BY_LOCATIONID } from "../../../gqloperations/queries";
import { useApolloClient } from "@apollo/client";
import { useHandleError } from "../../../hooks/useHandleError";
import { ExportGroup, Table } from "../../index";
import { toast } from "react-toastify";

function DebtCollectionCancellationReport() {
  const client = useApolloClient();
  const handleError = useHandleError();
  const [tableData, setTableData] = useState([]);
  const [variables, setVariables] = useState({});
  const [nextToken, setNextToken] = useState();
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const columns = [
    {
      accessorKey: "locationName",
      header: "Location",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "locationName",
    },
    {
      accessorKey: "aliasMemberId",
      header: "Member ID",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "aliasMemberId",
    },
    {
      accessorFn: (row) => `${row?.debtorFirstName}, ${row?.debtorLastName}`,
      header: "Debtor Name",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "memberName",
    },
    {
      accessorFn: (row) => format(new Date(row?.createdAt), "dd/MM/yyyy"),
      header: "Cancellation Date",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "createdAt",
    },
  ];
  const handleChange = (e) => {
    setSearchBarInputVal(e.target.value);
  };
  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };
  const queryReportData = async (variables) => {
    try {
      const result = await client.query({
        query: GET_DEBT_COLLECTION_CANCELLATION_BY_LOCATIONID,
        fetchPolicy: "no-cache",
        variables: variables,
      });
      if (result) {
        const data = result?.data?.getDebtCollectionsByLocationId?.items;
        const nextToken =
          result?.data?.getDebtCollectionsByLocationId?.nextToken;
        setNextToken(nextToken);
        if (variables.nextToken) {
          setTableData([...tableData, ...data]);
        } else {
          setTableData(data);
        }
        setIsLoading(false);
        return;
      }
    } catch (error) {
      handleError(`${error}`);
    }
  };
  const handleGenerate = (values) => {
    if (values.location === "-1") {
      toast.error("Please select a location");
      return;
    }
    setVariables({
      locationId: values.location,
    });
    queryReportData({
      locationId: values.location,
    });
  };
  const handleNextToken = () => {
    queryReportData({ locationId: variables.locationId, nextToken: nextToken });
  };

  return (
    <div>
      <ReportGeneratorFormWithMonth
        noTimezone
        brandAndLocation
        noDefaultSelect
        isClubLocationMandatory={true}
        handleSubmit={handleGenerate}
      />
      {isLoading && (
        <div className="report">
          <BeatLoader className="loading" color="white" size={20} />
        </div>
      )}
      {!isLoading && tableData.length > 0 && (
        <div className="actions-row report-tables mt-24 mb-12">
          <ExportGroup
            data={tableData ? tableData : []}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            sorting={sorting}
          />
          <div className="search-container">
            <form onSubmit={onSearchBarSubmit}>
              <div className="search-bar">
                <div className="search-bar-container">
                  <input
                    className="fs-10"
                    type="text"
                    name="search"
                    id="search"
                    value={searchBarInputVal}
                    onChange={handleChange}
                    placeholder="Search"
                  />
                  <button type="submit">
                    <AiOutlineSearch />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {!isLoading && tableData && (
        <div className="">
          <Table
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            sorting={sorting}
            setSorting={setSorting}
            columns={columns}
            rowsPerPage={10}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            nextToken={nextToken}
            handleNextToken={handleNextToken}
          />
        </div>
      )}
    </div>
  );
}

export default DebtCollectionCancellationReport;
