import "./PendingApproval.scss";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMemberContext } from "../../contexts/MemberContext";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  GET_MEMBER_BY_ID,
  search_REPORT_CONTRACTS,
} from "../../gqloperations/queries";
import {
  APPROVE_UNDER_18_MEMBER,
  CHARGE_SINGLE_PAYMENT,
  GET_STATUS_PAYMENT_UNDER_18,
  SIGNUP_MEMBER_UNDER_18,
  UPDATE_CONSENT_FORM,
} from "../../gqloperations/mutations";
import actions from "../../assets/images/icons/action_menu.png";
import { toast } from "react-toastify";
import getTypeIcon from "../../utils/getTypeIcon";
import { brandTypes } from "../../constants/commonConstant";
import { AiOutlineSearch } from "react-icons/ai";
import { SET_MEMBER_ID } from "../../actions";
import { BeatLoader } from "react-spinners";
import { isFutureDate } from "../../utils/getFutureDate";
import { format } from "date-fns";
import ChangeExpiryDateModal from "./ChangeExpiryDateModal";
import { useHandleError } from "../../hooks/useHandleError";
import {
  MakePaymentActivateModal,
  Table,
  Header,
  Button,
  Modal,
  StatusModal,
} from "../../components";
import MakePaymentAddMembershipModal from "../../components/modal/MakePaymentAddMembershipModal";

const PendingApproval = () => {
  const navigate = useNavigate();
  const {
    createLog,
    dispatch,
    getMemberById,
    sidebarLocationData,
    sidebarLocationId,
  } = useMemberContext();
  const ref = useRef();
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [showActions, setShowActions] = useState({});
  const [tableData, setTableData] = useState();
  const [curMemberDetails, setCurMemberDetails] = useState({ name: "" });
  const [activateMembershipAmount, setActivateMembershipAmount] = useState("");
  const [showModalPayment, setShowModalPayment] = useState(false);
  const handleError = useHandleError();

  const [approveUnderAgeMember, { loading: approveUnderAgeMemberLoading }] =
    useMutation(APPROVE_UNDER_18_MEMBER);
  const [signupUnderAgeMember, { loading: signupUnderAgeMemberLoading }] =
    useMutation(SIGNUP_MEMBER_UNDER_18);
  const [updateConsentForm, { loading: updateConsentFormLoading }] =
    useMutation(UPDATE_CONSENT_FORM);
  const [getPaymentTypeUnder18, { loading: getPaymentTypeUnder18Loading }] =
    useMutation(GET_STATUS_PAYMENT_UNDER_18);
  const [chargeSinglePayment, { loading: chargeSinglePaymentLoading }] =
    useMutation(CHARGE_SINGLE_PAYMENT);
  const homeLocationIdFilters =
    sidebarLocationId !== 0
      ? { locationId: { eq: sidebarLocationId } }
      : {
          or: sidebarLocationData?.flatMap((cur) => {
            if (!cur?.locationId) return [];
            return { locationId: { eq: cur.locationId } };
          }),
        };
  const variables = {
    filter: {
      ...homeLocationIdFilters,
      and: [{ isPending: { eq: true } }, { expiryDateTime: { exists: false } }],
    },
    sort: { direction: "desc", field: "createdAt" },
    nextToken: nextToken,
  };
  const [
    searchMembersContract,
    {
      loading: searchMembersContractLoading,
      error: searchMembersContractError,
      data: searchMembersData,
    },
  ] = useLazyQuery(search_REPORT_CONTRACTS, {
    fetchPolicy: "no-cache",
    variables: variables,
    onCompleted: (data) => {
      setTableData(data?.searchMemberContracts?.items);
      setNextToken(data?.searchMemberContracts?.nextToken);
    },
    onError: (error) => {
      handleError(`${error}`);
    },
  });

  const getPendingMemberships = () => {
    variables.nextToken = null;
    searchMembersContract({
      variables: variables,
      onCompleted: (data) => {
        setTableData(data?.searchMemberContracts?.items);
        setNextToken(data?.searchMemberContracts?.nextToken);
      },
      onError: (error) => {
        handleError(`${error}`);
      },
    });
  };

  const refetchQueries = [
    {
      query: GET_MEMBER_BY_ID,
      variables: {
        memberId: `${curMemberDetails?.memberId}`,
      },
    },
  ];

  useEffect(() => {
    getPendingMemberships();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarLocationId]);

  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };

  const handleNextToken = () => {
    searchMembersContract({
      variables: variables,
      onCompleted: (data) => {
        setTableData([...tableData, ...data?.searchMemberContracts?.items]);
        setNextToken(data?.searchMemberContracts?.nextToken);
      },
      onError: (error) => {
        handleError(`${error}`);
      },
    });
  };
  const handleRowClick = (id, email) => {
    dispatch({
      type: SET_MEMBER_ID,
      payload: {
        memberId: id,
      },
    });
    navigate(`/dashboard/member/${id}`);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!ref?.current || ref.current.contains(e.target)) {
        return;
      }
      setShowActions({});
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [ref]);
  const columns = [
    {
      accessorFn: (row) => {
        const memberName = `${row?.memberDetails?.givenName} ${row?.memberDetails?.surname}`;
        return memberName;
      },
      header: "NAME",

      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "name",
    },
    {
      accessorFn: (row) => row?.memberDetails?.email,
      cell: (info) => info.getValue(),
      header: "Email",
      enableSorting: true,
      enableGlobalFilter: false,
      id: "email",
    },

    {
      accessorFn: (row) => row?.memberDetails?.homeLocationDetails?.name,
      header: "Location",
      enableSorting: false,
      enableGlobalFilter: false,
      id: "location",
      accessorKey: "location",
      cell: (info) => info.getValue(),
    },
    {
      accessorFn: (originalRow) => {
        if (!originalRow?.memberDetails?.brandDetails?.name) return;
        const brandName = originalRow?.memberDetails?.brandDetails
          ? originalRow?.memberDetails?.brandDetails?.name
          : "";
        const icon = getTypeIcon(brandName, brandTypes);
        return icon;
      },

      header: "Brand",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: true,
      enableGlobalFilter: false,
      id: "branddetails",
    },
    {
      id: "row-actions5",
      header: "ACTIONS",
      cell: (props) => {
        const id = props.row.original?.memberDetails?.memberId;
        const contracts = props?.row?.original?.memberDetails?.contracts;
        const memberContractId = props?.row?.original?.id;
        const paymentDetails =
          props?.row?.original?.memberDetails?.paymentDetails;

        const isContractsPending = contracts?.items.find(
          (item) => item.isPending
        );
        const contractNotExist =
          !isContractsPending?.juniorMemberconsentFormDetail?.signature &&
          isContractsPending?.isPending;

        const memberHasSigned =
          contracts?.items.length &&
          isContractsPending?.juniorMemberconsentFormDetail?.signature;

        if (contracts?.length < 0) return;

        const paymentId = paymentDetails?.items[0]?.id;

        const areActionsVisible = showActions.hasOwnProperty(id);
        return (
          <div className="action-container">
            <img
              className="dropdown-btn"
              src={actions}
              onClick={(e) => {
                e.stopPropagation();
                setShowActions({
                  [id]: true,
                });
              }}
              alt=""
            />
            {areActionsVisible && (
              <span className="action-options-menu" ref={ref}>
                <ul className="membership-options">
                  <li
                    onClick={() => {
                      const id = props.row.original?.memberDetails?.memberId;
                      const email = props.row.original?.memberDetails?.email;
                      handleRowClick(id, email);
                      createLog(
                        `Clicked ${props.row.original?.memberDetails?.givenName} ${props.row.original?.memberDetails?.surname}`,
                        "member",
                        `${props.row.original?.memberDetails?.memberId}`
                      );
                    }}
                  >
                    <Button
                      name="VIEW"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                  <li
                    onClick={() => {
                      handleComponent("edit", {
                        consentFormId:
                          contracts?.items[0]?.juniorMemberconsentFormDetail
                            ?.id,
                        consentFormInviteTokenExpiry:
                          contracts?.items[0]?.juniorMemberconsentFormDetail
                            ?.consentFormInviteTokenExpiry,
                        memberDetails: props.row.original?.memberDetails,
                      });
                    }}
                  >
                    <Button
                      name="Edit"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                  <li
                    onClick={() => {
                      if (memberHasSigned) {
                        handleComponent("activate-confirm", {
                          ...contracts?.items.find((item) => item.isPending),
                          memberId: id,
                          operationName: "approveUnder18Member",
                          memberContractId: memberContractId,
                          paymentInformationId: paymentId,
                          memberDetails: props.row.original?.memberDetails,
                          // membershipId: props.row.original?.membershipId,
                        });
                      }
                    }}
                  >
                    <Button
                      name="ACTIVATE"
                      btntype="submit"
                      btnname="submit"
                      className={` btn ${
                        !memberHasSigned
                          ? "btn-disabled"
                          : "btn-transparent btn-small"
                      }`}
                    />
                  </li>
                  <li
                    onClick={() => {
                      if (contractNotExist) {
                        handleComponent("resend-consent", {
                          memberId: id,
                        });
                      }
                    }}
                  >
                    <Button
                      name="resend consent form"
                      btntype="submit"
                      btnname="submit"
                      className={` btn ${
                        !contractNotExist
                          ? "btn-disabled"
                          : "btn-transparent btn-small"
                      }`}
                    />
                  </li>
                </ul>
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const handleComponent = (componentName, rowObj) => {
    const isPaymentDelay = rowObj?.paymentDelay?.amount !== 0;
    const voucherDetail = rowObj?.voucherDetail;
    const isVoucherDelay =
      voucherDetail?.paymentDelay?.amount !== 0 &&
      voucherDetail?.paymentDelay?.paymentDelay !== null;
    let isDisableMembershipAction;
    if (isPaymentDelay && rowObj) {
      const params = {
        startDateTime: rowObj?.startDateTime,
        amount: rowObj?.paymentDelay?.amount,
        type: rowObj?.paymentDelay?.type,
      };

      isDisableMembershipAction = rowObj?.startDateTime
        ? isFutureDate(params)
        : true;
    }
    setActivateMembershipAmount(
      Object.keys(voucherDetail || {}).length
        ? isVoucherDelay
          ? voucherDetail?.joiningFee +
            voucherDetail?.activationFee +
            voucherDetail?.passFee
          : voucherDetail?.cost +
            voucherDetail?.joiningFee +
            voucherDetail?.activationFee +
            voucherDetail?.passFee
        : isDisableMembershipAction
        ? rowObj?.joiningFee + rowObj?.joiningFee2 + rowObj?.joiningFee3
        : rowObj?.costPrice +
          rowObj?.joiningFee +
          rowObj?.joiningFee2 +
          rowObj?.joiningFee3
    );

    setCurMemberDetails({ name: componentName, ...rowObj });
  };
  const handleActivateClick = async () => {
    await getPaymentTypeUnder18({
      variables: {
        memberId: curMemberDetails?.memberId,
        operationName: "getStatusPaymentUnder18",
      },
      onCompleted: (data) => {
        const paymentType = data.getStatusPaymentUnder18.paymentType;
        if (paymentType === "CASH" || paymentType === "EFTPOS") {
          chargeSinglePayment({
            variables: {
              memberId: curMemberDetails?.memberId,
              currentPaymentOption: paymentType,
              operation: "MAV",
              membershipActivationArguments: {
                isPaidInHub: true,
                // membershipId: curMemberDetails?.membershipId,
              },
              brandId: curMemberDetails?.memberDetails?.brandDetails.id,
              paymentInformationId:
                data.getStatusPaymentUnder18.futurePaymentInformationId,
              amount: activateMembershipAmount,
            },
            onCompleted: (data) => {
              getPendingMemberships();
              setCurMemberDetails({ name: "" });
              toast.success("Successfully activate membership");
            },
            onError: (error) => {
              setCurMemberDetails({ name: "" });
              handleError(`${error}`);
            },
          });
        } else {
          if (activateMembershipAmount === 0) {
            approveUnderAgeMember({
              fetchPolicy: "no-cache",
              variables: {
                memberId: curMemberDetails?.memberId,
                operationName: curMemberDetails?.operationName,
                memberContractId: curMemberDetails?.memberContractId,
                paymentInformationId: curMemberDetails?.paymentInformationId,
              },
              onCompleted: (data) => {
                getPendingMemberships();
                setCurMemberDetails({ name: "" });
                toast.success("Successfully activated membership");
                dispatch({
                  type: SET_MEMBER_ID,
                  payload: {
                    memberId: curMemberDetails?.memberId,
                  },
                });
              },
              onError: (err) => {
                handleError("Error: Cannot activate membership");
              },
            });
          } else {
            setCurMemberDetails({ ...curMemberDetails, name: "" });
            setShowModalPayment(true);
          }
        }
      },
    });
  };

  const modalClose = () => {
    setCurMemberDetails({ name: "" });
  };
  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);

    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };
  const handleResendClick = (memberId) => {
    const payload = {
      memberId: curMemberDetails?.memberId,
      operation: "RESEND_CONSENT_FORM",
      prospectId: "false",
      membershipStartDate: format(new Date(), "yyyy-MM-dd"),
    };
    signupUnderAgeMember({
      variables: {
        input: payload,
      },
    })
      .then((res) => {
        setCurMemberDetails({ name: "" });
        getPendingMemberships();
        toast.success("Successfully resend consent form");
      })
      .catch((e) => {
        setCurMemberDetails({ name: "" });
        getPendingMemberships();
        handleError("Error: Cannot resend consent form");
      });
  };

  const handleChangeExpiryDateClick = (consentFormInviteTokenExpiry) => {
    const payload = {
      id: curMemberDetails?.consentFormId,
      consentFormInviteTokenExpiry: consentFormInviteTokenExpiry,
    };
    updateConsentForm({
      variables: {
        input: payload,
      },
    })
      .then((res) => {
        setCurMemberDetails({ name: "" });
        getPendingMemberships();
        toast.success("Successfully edit expiry date of consent form");
      })
      .catch((e) => {
        setCurMemberDetails({ name: "" });
        getPendingMemberships();
        handleError("Error: Cannot edit expiry date of consent form");
      });
  };

  return (
    <div className="medicalSuspension-container">
      <Header pageTitle="Pending approval (Under -18)" />
      {curMemberDetails?.name && (
        <Modal>
          {curMemberDetails?.name === "activate-confirm" && (
            <StatusModal
              loading={
                approveUnderAgeMemberLoading ||
                getPaymentTypeUnder18Loading ||
                chargeSinglePaymentLoading
              }
              curRow={curMemberDetails}
              modalHeaderTxt={"Activate Membership"}
              modalParaText={
                "Are you sure you want to activate this membership?"
              }
              handleStatusClick={handleActivateClick}
              modalClose={modalClose}
            />
          )}
          {curMemberDetails?.name === "resend-consent" && (
            <StatusModal
              curRow={curMemberDetails}
              modalHeaderTxt={"Resend Consent Form"}
              modalParaText={"Are you sure you want to resend consent form?"}
              handleStatusClick={handleResendClick}
              modalClose={modalClose}
              loading={signupUnderAgeMemberLoading}
            />
          )}
          {curMemberDetails?.name === "edit" && (
            <ChangeExpiryDateModal
              cancelFn={modalClose}
              headerText={"Edit"}
              expiryDate={curMemberDetails?.consentFormInviteTokenExpiry}
              memberDetails={curMemberDetails?.memberDetails}
              handleSubmit={handleChangeExpiryDateClick}
            />
          )}
        </Modal>
      )}
      <div className="actions-row mt-32 feature-row">
        <div className="right-elements">
          <div className="search-and-button-row">
            <div className="search-container">
              <form onSubmit={onSearchBarSubmit}>
                <div className="search-bar">
                  <div className="search-bar-container">
                    <input
                      className="fs-10"
                      type="text"
                      name="search"
                      id="search"
                      value={searchBarInputVal}
                      onChange={handleChange}
                      placeholder="Member name"
                    />
                    <button type="submit">
                      <AiOutlineSearch />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {searchMembersContractLoading && (
        <div className="dashboard-loading">
          <BeatLoader className="loading" color="white" size={20} />
        </div>
      )}
      {showModalPayment && (
        <MakePaymentAddMembershipModal
          amount={activateMembershipAmount}
          memberDetail={curMemberDetails?.memberDetails}
          membershipType="weekly"
          operation="MAV"
          // membershipId={curMemberDetails?.membershipId}
          setShowModalPayment={setShowModalPayment}
          locationId={sidebarLocationId}
          onApiCompleted={() => {
            toast.success("Membership activated successfully!");
            setCurMemberDetails({ name: "" });
            getPendingMemberships();
          }}
          onApiError={(e) => handleError(`${e}`)}
          apiRefetchQueries={refetchQueries}
        />
      )}
      <div className="landing-table u18-approve">
        {!searchMembersContractLoading && !searchMembersContractError && (
          <Table
            onRowClick={(i) => {
              const id = i?.memberDetails?.memberId;
              const email = i?.memberDetails?.email;
              handleRowClick(id, email);
              createLog(
                `Clicked ${i?.memberDetails?.givenName} ${i?.memberDetails?.surname}`,
                "member",
                `${i?.memberDetails?.memberId}`
              );
            }}
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            sorting={sorting}
            setSorting={setSorting}
            rowsPerPage={10}
            handleNextToken={handleNextToken}
            nextToken={nextToken}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
          />
        )}
      </div>
    </div>
  );
};

export default PendingApproval;
