import "./memberreport.scss";
import React, { useEffect, useState } from "react";
import { useTableStateProps } from "../../hooks/useTableStateprops";
import { Button, Table, Toggle } from "../../components";
import { AiOutlineSearch } from "react-icons/ai";
import { dateFormatForColumn } from "../../utils/dateFormatForColumn";
import { format, parseISO } from "date-fns";
import getTypeIcon from "../../utils/getTypeIcon";
import { brandTypes } from "../../constants/commonConstant";
import { useMemberContext } from "../../contexts/MemberContext";
import { BeatLoader } from "react-spinners";
import useCustomFetch from "../../hooks/useCustomFetch";
import { API } from "../../utils/API";
import { toast } from "react-toastify";
import { ReportGeneratorFormWithMonth } from "..";
import { useCheckMemberProfile } from "../../hooks/useCheckMemberProfile";
import { useHandleError } from "../../hooks/useHandleError";

const MemberReport = () => {
  const [tableData, setTableData] = useState([]);
  const {
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
  } = useTableStateProps();

  const { sidebarBrandName } = useMemberContext();
  const handleError = useHandleError();
  const [searchbarInputVal, setSearchbarInputVal] = useState("");
  const [rowSelection, setRowSelection] = useState([]);
  const [category, setCategory] = useState("");
  const [nextToken, setNextToken] = useState();
  const [endPoint, setEndPoint] = useState();
  const [summaryEndpoint, setSummaryEndpoint] = useState();
  const [params, setParams] = useState();
  const [exportEndpoint, setExportEndpoint] = useState();
  const { data, isLoading, error } = useCustomFetch(
    endPoint && endPoint,
    params && params
  );
  const { checkMemberProfile } = useCheckMemberProfile();
  const { data: summary } = useCustomFetch(
    summaryEndpoint?.endPoint && summaryEndpoint?.endPoint,
    summaryEndpoint?.params && summaryEndpoint?.params
  );
  const { data: exportData } = useCustomFetch(
    exportEndpoint?.endPoint && exportEndpoint?.endPoint,
    exportEndpoint?.params && exportEndpoint?.params
  );

  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchbarInputVal(searchVal);
    // When searchBar is empty, tableData should no longer be filtered
    if (searchVal?.length === 0) {
      setGlobalFilter(searchVal);
    }
  };

  const onSearchbarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchbarInputVal));
  };

  useEffect(() => {
    handleNextToken();
  }, [nextToken]);

  const handleNextToken = () => {
    setParams({ ...params, page: params?.page + 1 });
  };

  const handleGenerateSubmit = async (values, OnSubmitProps) => {
    setTableData([]);
    const { chooseDateFrom, chooseDateTo, reportCategory } = values;
    const params = { brandId: values.brand, locationId: values.location };
    if (values.location === "-1") {
      delete params.locationId;
    }
    params.type = (() => {
      switch (reportCategory) {
        case "BLOCKED":
          setCategory("BLOCKED");
          return "blocked";
        case "SUSPENDED":
          setCategory("SUSPENDED");
          return "suspended";
        case "EXPIRED":
          setCategory("EXPIRED");
          return "expired";
        case "LEAVERS":
          setCategory("LEAVERS");
          return "left";
        case "JOINERS":
          setCategory("JOINERS");
          return "joined";
        case "OUTSTANDING":
          setCategory("OUTSTANDING");
          return "outstanding";
        case "FUTURE":
          setCategory("FUTURE");
          return "future";
        default:
          setCategory("ALL ACTIVE");
          return "active";
      }
    })();

    if (chooseDateFrom && chooseDateTo) {
      params.startDate = chooseDateFrom;
      params.endDate = chooseDateTo;
      setEndPoint(API.memberReport);
      setExportEndpoint({ endPoint: API.memberReportExport, params: params });
      setSummaryEndpoint({ endPoint: API.memberReportSummary, params: params });
      setParams({ ...params, page: 1, limit: 100 });
    }
  };
  useEffect(() => {
    if (error && endPoint) {
      setTableData([]);
      handleError(error?.response?.data?.message ?? "Error when fetching api");
    } else if (data?.data && params.page === 1) setTableData(data?.data.data);
    if (data?.data && params.page > 1)
      setTableData([...tableData, ...data?.data?.data]);
  }, [data, error]);

  const suspendedColumn = [
    {
      accessorFn: (row) =>
        row.surname && row.givenName ? `${row.surname}, ${row.givenName}` : "",
      header: "SURNAME, NAME",
      cell: (info) => {
        return info.getValue();
      },
      footer: (info) => "TOTAL",
      enableSorting: true,
      enableGlobalFilter: true,
      id: "surname2",
    },
    {
      // accessorKey: "aliasMemberId",
      accessorFn: (originalRow) => originalRow?.aliasMemberId?.toString() ?? "",
      header: "MEMBER ID",
      cell: (info) => info.getValue(),

      enableSorting: false,
      enableGlobalFilter: true,
      id: "memberid2",
    },
    {
      accessorFn: (originalRow) => originalRow?.brandDetails?.name,
      header: "BRAND",
      cell: (info) => {
        const icon = getTypeIcon(sidebarBrandName, brandTypes);
        return icon;
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "homeclub2",
    },
    {
      accessorFn: (originalRow) => originalRow?.homeLocationName,
      header: "HOME CLUB",
      cell: (info) => {
        // const icon = getTypeIcon(sidebarBrandName, brandTypes);
        return info.getValue();
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "homeclub2",
    },
    {
      accessorKey: "mobileNumber",
      header: "MOBILE",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "mobile2",
    },
    {
      accessorKey: "email",
      header: "EMAIL",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "email2",
    },
    {
      accessorKey: "joinedDateTime",
      header: "JOIN DATE",
      cell: (info) => {
        const dateValue = info.getValue();
        return dateValue ? format(parseISO(dateValue), "dd/MM/yyyy") : "";
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "joindate2",
    },
    {
      accessorFn: (originalRow) => {
        const active = originalRow?.contracts;
        if (active?.length > 1)
          return (
            <details>
              <summary>
                {dateFormatForColumn(active[0].startDateTime)},...
              </summary>
              {active?.map((i, index) => (
                <p key={index}>
                  {index + 1 + ". " + dateFormatForColumn(i?.startDateTime)}
                </p>
              ))}
            </details>
          );
        else if (active?.length === 1)
          return active?.map((i, index) => (
            <p key={index}>{dateFormatForColumn(i?.startDateTime)}</p>
          ));
        else return "";
      },
      header: "START DATE",
      cell: (info) => info.getValue(),

      enableSorting: false,
      enableGlobalFilter: false,
      id: "startdate2",
    },
    {
      accessorKey: "memberType",
      header: "TYPE",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "type2",
    },
    {
      accessorFn: (originalRow) => {
        const active = originalRow?.contracts;
        if (active?.length > 1)
          return (
            <details>
              <summary>
                {active[0]?.paymentFrequency?.amount +
                  " " +
                  active[0]?.paymentFrequency?.type}
                ,...
              </summary>
              {active?.map((i, index) => {
                return (
                  <p key={index}>
                    {index +
                      1 +
                      ". " +
                      (i?.paymentFrequency?.amount +
                        " " +
                        i?.paymentFrequency?.type)}
                  </p>
                );
              })}
            </details>
          );
        else if (active?.length === 1)
          return active?.map((i, index) => {
            return (
              <p key={index}>
                {i?.paymentFrequency?.amount + " " + i?.paymentFrequency?.type}
              </p>
            );
          });
        else return "";
      },
      header: "BILLING FREQUENCY",
      cell: (info) => info.getValue(),

      enableSorting: false,
      enableGlobalFilter: false,
      id: "billingfrequency2",
    },
    {
      accessorKey: "outstandingBalance",

      header: "OUTSTANDING BALANCE",
      cell: (info) => {
        return info.getValue() ? "$" + info.getValue().toFixed(2) : "$0.00";
      },
      footer: () =>
        data && data.data
          ? "$" + Number(data.data.total.outstandingAmount)?.toFixed(2)
          : "$0.00",

      enableSorting: false,
      enableGlobalFilter: false,
      id: "billingbalance2",
    },
    {
      accessorKey: "totalDD",
      header: "TOTAL DIRECT DEBITS",
      cell: (info) => {
        return "$" + Number(info.getValue())?.toFixed(2);
      },
      footer:
        data && data.data
          ? "$" + Number(data.data.total.ddAmount)?.toFixed(2)
          : "$0.00",
      enableSorting: false,
      enableGlobalFilter: false,
      id: "totaldirectdebits2",
    },
    {
      accessorFn: (originalRow) => {
        const active = originalRow?.contracts;
        if (active?.length > 1)
          return (
            <details>
              <summary>
                {active[0].expiryDateTime
                  ? dateFormatForColumn(active[0]?.expiryDateTime)
                  : dateFormatForColumn(active[0]?.endDateTime)}
                ,...
              </summary>
              {active?.map((i, index) => {
                return !(
                  i?.expiryDateTime === null && i?.endDateTime === null
                ) ? (
                  <p key={index}>
                    {index +
                      1 +
                      ". " +
                      (i.expiryDateTime
                        ? dateFormatForColumn(i?.expiryDateTime)
                        : dateFormatForColumn(i?.endDateTime))}
                  </p>
                ) : (
                  <p key={index}>no Expiry</p>
                );
              })}
            </details>
          );
        else if (active?.length === 1)
          return active?.map((i, index) => {
            return (
              !(i?.expiryDateTime === null && i?.endDateTime === null) && (
                <p key={index}>
                  {i.expiryDateTime
                    ? dateFormatForColumn(i?.expiryDateTime)
                    : dateFormatForColumn(i?.endDateTime)}
                </p>
              )
            );
          });
        else return "";
      },
      header: "DATE EXPIRING",
      cell: (info) => info.getValue(),

      enableSorting: false,
      enableGlobalFilter: false,
      id: "dateexpiring",
    },
    {
      accessorKey: "totalFailedDD",
      header: "DD FAILED PAYMENT",
      cell: (info) => "$" + (Number(info.getValue())?.toFixed(2) ?? "0.00"),

      footer:
        data && data.data
          ? "$" + Number(data.data.total.failedDDAmount)?.toFixed(2)
          : "$0.00",
      enableSorting: false,
      enableGlobalFilter: false,
      id: "ddfailedpayment",
    },
    {
      accessorFn: (originalRow) => {
        const suspend = originalRow?.suspensions;
        if (suspend?.length > 1)
          return (
            <details>
              <summary>
                {dateFormatForColumn(suspend[0].startDateTime)},...
              </summary>
              {suspend?.map((i, index) => (
                <p key={index}>
                  {index +
                    1 +
                    ". " +
                    (i?.startDateTime
                      ? dateFormatForColumn(i?.startDateTime)
                      : "")}
                </p>
              ))}
            </details>
          );
        else if (suspend?.length === 1)
          return suspend?.map((i, index) => (
            <p key={index}>
              {i?.startDateTime ? dateFormatForColumn(i?.startDateTime) : ""}
            </p>
          ));
        else return "";
      },
      header: "SUSPENSION START DATE",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "suspensionstartdate",
    },
    {
      accessorFn: (originalRow) => {
        const suspend = originalRow?.suspensions;
        // originalRow?.contracts?.items?.[0]?.suspensions
        if (suspend?.length > 1)
          return (
            <details>
              <summary>
                {dateFormatForColumn(suspend[0].endDateTime)},...
              </summary>
              {suspend?.map((i, index) => {
                return (
                  <p key={index}>
                    {index +
                      1 +
                      ". " +
                      (i?.endDateTime
                        ? dateFormatForColumn(i?.endDateTime)
                        : "")}
                    <br />
                  </p>
                );
              })}
            </details>
          );
        else if (suspend?.length === 1)
          return suspend?.map((i, index) => {
            return (
              <p key={index}>
                {i?.endDateTime ? dateFormatForColumn(i?.endDateTime) : ""}
              </p>
            );
          });
        else return "";
      },
      header: "SUSPENSION END DATE",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "suspensionenddate",
    },
  ];

  const columns = [
    {
      accessorFn: (row) =>
        row.surname && row.givenName ? `${row.surname}, ${row.givenName}` : "",
      header: "SURNAME, NAME",
      cell: (info) => {
        return info.getValue();
      },
      footer: (info) => "TOTAL",
      enableSorting: true,
      enableGlobalFilter: true,
      id: "surname",
    },
    {
      // accessorKey: "aliasMemberId",
      accessorFn: (originalRow) => originalRow?.aliasMemberId?.toString() ?? "",
      header: "MEMBER ID",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "memberid",
    },
    {
      accessorFn: (originalRow) => originalRow?.brandDetails?.name,
      header: "BRAND",
      cell: (info) => {
        const icon = getTypeIcon(sidebarBrandName, brandTypes);
        return icon;
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "homeclub",
    },
    {
      accessorFn: (originalRow) => originalRow?.homeLocationName,
      header: "HOME CLUB",
      cell: (info) => {
        // const icon = getTypeIcon(sidebarBrandName, brandTypes);
        return info.getValue();
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "homeclub2",
    },
    {
      accessorKey: "mobileNumber",
      header: "MOBILE",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "mobile",
    },
    {
      accessorKey: "email",
      header: "EMAIL",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "email",
    },
    {
      accessorKey: "joinedDateTime",
      header: "JOIN DATE",
      cell: (info) => {
        const dateValue = info.getValue();
        return dateValue ? format(parseISO(dateValue), "dd/MM/yyyy") : "";
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "joindate1",
    },

    {
      accessorFn: (originalRow) => {
        const active = originalRow?.contracts;
        if (active?.length > 1)
          return (
            <details>
              <summary>
                {dateFormatForColumn(active[0].startDateTime)},...
              </summary>
              {active?.map((i, index) => (
                <p key={index}>
                  {index + 1 + ". " + dateFormatForColumn(i?.startDateTime)}
                </p>
              ))}
            </details>
          );
        else if (active?.length === 1)
          return active?.map((i, index) => (
            <p key={index}>{dateFormatForColumn(i?.startDateTime)}</p>
          ));
        else return "";
      },
      header: "START DATE",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "startdate1",
    },
    {
      accessorKey: "memberType",
      header: "TYPE",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "type1",
    },
    // {
    //   // accessorKey: "contracts",
    //   accessorFn: (originalRow) =>
    //     originalRow?.contracts?.items?.[0]?.isActive ? "ACTIVE" : "INACTIVE",
    //   header: "STATUS",
    //   cell: (info) => info.getValue(),
    //   enableSorting: false,
    //   enableGlobalFilter: false,
    //   id: "status1",
    // },

    {
      accessorFn: (originalRow) => {
        const active = originalRow?.contracts;
        if (active?.length > 1)
          return (
            <details>
              <summary>{active[0].name},...</summary>
              {active?.map((i, index) => (
                <p key={index}>{index + 1 + ". " + i?.name}</p>
              ))}
            </details>
          );
        else if (active?.length === 1)
          return active?.map((i, index) => <p key={index}>{i?.name}</p>);
        else return "";
      },
      header: "MEMBERSHIP NAME",
      cell: (info) => {
        return info.getValue();
      },

      enableSorting: false,
      enableGlobalFilter: false,
      id: "membershipName1",
    },
    {
      accessorFn: (originalRow) => {
        const active = originalRow?.contracts;
        if (active?.length > 1)
          return (
            <details>
              <summary>{active[0].costPrice.toFixed(2)},...</summary>
              {active?.map((i, index) => (
                <p key={index}>
                  {index + 1 + ". " + (i?.costPrice).toFixed(2)}
                </p>
              ))}
            </details>
          );
        else if (active?.length === 1)
          return active?.map((i, index) => (
            <p key={index}>{(i?.costPrice).toFixed(2)}</p>
          ));
        else return "";
      },
      header: "MEMBERSHIP PRICE",
      cell: (info) => {
        return info.getValue();
      },

      enableSorting: false,
      enableGlobalFilter: false,
      id: "membershipPrice1",
    },
    {
      // accessorFn: (originalRow) => originalRow?.contracts?.items?.[0]?.billings,
      accessorKey: "outstandingBalance",
      // accessorFn: (originalRow) => originalRow?.contracts?.items?.[0]?.billings,
      header: "OUTSTANDING BALANCE",
      cell: (info) => {
        return info.getValue() ? "$" + info.getValue().toFixed(2) : "$0.00";
      },
      footer:
        data && data.data
          ? "$" + Number(data.data.total.outstandingAmount)?.toFixed(2)
          : "$0.00",

      enableSorting: false,
      enableGlobalFilter: false,
      id: "billingbalance1",
    },

    {
      accessorKey: "ddFailureCount",
      header: "DD FAILED COUNT",
      cell: (info) => {
        return info.getValue() ? info.getValue() : 0;
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "ddfailedcount",
    },

    {
      accessorKey: "totalDD",
      header: "TOTAL DIRECT DEBITS",
      cell: (info) => {
        return "$" + Number(info.getValue())?.toFixed(2);
      },
      footer:
        data && data.data
          ? "$" + Number(data.data.total.ddAmount)?.toFixed(2)
          : "$0.00",
      enableSorting: false,
      enableGlobalFilter: false,
      id: "totaldirectdebits1",
    },

    {
      accessorFn: (originalRow) => {
        const active = originalRow?.contracts;
        if (active?.length > 1)
          return (
            <details>
              <summary>
                {active[0].expiryDateTime
                  ? dateFormatForColumn(active[0]?.expiryDateTime)
                  : dateFormatForColumn(active[0]?.endDateTime)}
                ,...
              </summary>
              {active?.map((i, index) => {
                return !(
                  i?.expiryDateTime === null && i?.endDateTime === null
                ) ? (
                  <p key={index}>
                    {index +
                      1 +
                      ". " +
                      (i.expiryDateTime
                        ? dateFormatForColumn(i?.expiryDateTime)
                        : dateFormatForColumn(i?.endDateTime))}
                  </p>
                ) : (
                  <p key={index}>no Expiry</p>
                );
              })}
            </details>
          );
        else if (active?.length === 1)
          return active?.map((i, index) => {
            return (
              !(i?.expiryDateTime === null && i?.endDateTime === null) && (
                <p key={index}>
                  {i.expiryDateTime
                    ? dateFormatForColumn(i?.expiryDateTime)
                    : dateFormatForColumn(i?.endDateTime)}
                </p>
              )
            );
          });
        else return "";
      },
      header: "DATE EXPIRING",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "dateexpiring1",
    },
    {
      accessorKey: "totalFailedDD",
      header: "DD FAILED PAYMENT",
      // accessorKey: "countFailedDD",
      cell: (info) => "$" + (Number(info.getValue())?.toFixed(2) ?? "0.00"),
      footer:
        data && data.data
          ? "$" + Number(data.data.total.failedDDAmount)?.toFixed(2)
          : "$0.00",
      enableSorting: false,
      enableGlobalFilter: false,
      id: "ddfailedpayment1",
    },
    {
      accessorFn: (originalRow) => {
        const suspend = originalRow?.suspensions;
        if (suspend?.length > 1)
          return (
            <details>
              <summary>
                {dateFormatForColumn(suspend[0].startDateTime)},...
              </summary>
              {suspend?.map((i, index) => (
                <p key={index}>
                  {index +
                    1 +
                    ". " +
                    (i?.startDateTime
                      ? dateFormatForColumn(i?.startDateTime)
                      : "")}
                </p>
              ))}
            </details>
          );
        else if (suspend?.length === 1)
          return suspend?.map((i, index) => (
            <p key={index}>
              {i?.startDateTime ? dateFormatForColumn(i?.startDateTime) : ""}
            </p>
          ));
        else return "";
      },
      header: "SUSPENSION START DATE",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "suspensionstartdate1",
    },
    {
      accessorFn: (originalRow) => {
        const suspend = originalRow?.suspensions;
        // originalRow?.contracts?.items?.[0]?.suspensions
        if (suspend?.length > 1)
          return (
            <details>
              <summary>
                {dateFormatForColumn(suspend[0].endDateTime)},...
              </summary>
              {suspend?.map((i, index) => {
                return (
                  <p key={index}>
                    {index +
                      1 +
                      ". " +
                      (i?.endDateTime
                        ? dateFormatForColumn(i?.endDateTime)
                        : "")}
                    <br />
                  </p>
                );
              })}
            </details>
          );
        else if (suspend?.length === 1)
          return suspend?.map((i, index) => {
            return (
              <p key={index}>
                {i?.endDateTime ? dateFormatForColumn(i?.endDateTime) : ""}
              </p>
            );
          });
        else return "";
      },
      header: "SUSPENSION END DATE",
      cell: (info) => {
        return info.getValue();
      },

      enableSorting: false,
      enableGlobalFilter: false,
      id: "suspensionenddate1",
    },
  ];
  const exportToExcel = () => {
    const a = document.createElement("a");
    a.href = exportData.data.data;
    a.target = "_blank";
    a.click();
    a.remove();
  };
  const [isSummary, setIsSummary] = useState(false);
  const sumColumn = [
    {
      accessorKey: "locationName",
      header: "LOCATION",
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "activeMembersCount",
      header: "TOTAL MEMBERS",
      footer:
        data && data.data ? Number(summary.data.total.activeMembersCount) : "0",
      enableSorting: false,
      enableGlobalFilter: false,
      id: "ddfailedpayment",
    },
  ];

  return (
    <div>
      <div className="member">
        <div className="member-containers reports">
          <ReportGeneratorFormWithMonth
            brandAndLocation
            // onlyStartDate
            noTimezone
            startDateRequired
            optionFirst={[
              { id: "ALL ACTIVE", name: "ALL ACTIVE" },
              { id: "JOINERS", name: "JOINERS" },
              { id: "LEAVERS", name: "LEAVERS" },
              { id: "EXPIRED", name: "EXPIRED" },
              { id: "SUSPENDED", name: "SUSPENDED" },
              { id: "BLOCKED", name: "BLOCKED" },
              { id: "OUTSTANDING", name: "OUTSTANDING" },
              { id: "FUTURE", name: "FUTURE" },
            ]}
            handleSubmit={(values, OnSubmitProps) => {
              handleGenerateSubmit(values, OnSubmitProps);
            }}
          />
          {tableData?.length > 0 && (
            <div className="action-row">
              <div className="action-buttons">
                <Button
                  name="csv"
                  btntype="submit"
                  btnname="icon-btn"
                  className="btn btn-transparent fs-12"
                  icon="csv_icon"
                  click={exportToExcel}
                />
                <Toggle
                  label="SUMMARY REPORT"
                  id="hasVirtualClass"
                  name="Virtual Class"
                  value={isSummary}
                  checked={isSummary}
                  onChange={(e) => {
                    setIsSummary(e.target.checked);
                  }}
                />
              </div>
              {!isSummary && (
                <div className="search-container">
                  <form onSubmit={onSearchbarSubmit}>
                    <div className="search-bar">
                      <div className="search-bar-container">
                        <input
                          className="fs-10"
                          type="text"
                          name="search"
                          id="search"
                          value={searchbarInputVal}
                          onChange={handleChange}
                          placeholder="Search by ID, surname or email"
                        />
                        <button type="submit">
                          <AiOutlineSearch />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          )}

          {isLoading && (
            <BeatLoader className="loading" color="white" size={20} />
          )}
          {!isLoading && (
            <div className="report-table-container-all">
              {tableData.length > 0 && (
                <p className="mt-24 fs-14">
                  {" "}
                  TOTAL {category === "ALL ACTIVE" ? "ACTIVE" : category}
                  {": "}
                  {data && data.data
                    ? isSummary
                      ? summary.data.total.activeMembersCount
                      : data.data.total.records
                    : isLoading && (
                        <BeatLoader
                          className="loading"
                          color="white"
                          size={10}
                        />
                      )}
                </p>
              )}
              <Table
                onRowClick={(i) => {
                  checkMemberProfile(i.memberName, i.memberId);
                }}
                data={isSummary ? summary?.data.data : tableData}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                sorting={sorting}
                setSorting={setSorting}
                columns={
                  isSummary
                    ? sumColumn
                    : category === "SUSPENDED"
                    ? suspendedColumn
                    : columns
                }
                rowsPerPage={10}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                hasFooter={true}
                nextToken={
                  !isSummary && tableData.length < data?.data?.total?.records
                }
                handleNextToken={handleNextToken}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MemberReport;
