import React, { useEffect, useState } from "react";
import { ReportGeneratorFormWithMonth } from "../../../pages";
import { BeatLoader } from "react-spinners";
import ExportGroup from "../../export/ExportGroup";
import { AiOutlineSearch } from "react-icons/ai";
import { API } from "../../../utils/API";
import useCustomFetch from "../../../hooks/useCustomFetch";
import { useHandleError } from "../../../hooks/useHandleError";
import Table from "../../table/Table";
import { format } from "date-fns";

function SnapshotReport() {
  const [tableData, setTableData] = useState([]);
  const [generate, setGenerate] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [endPoint, setEndPoint] = useState();
  const [params, setParams] = useState();
  const handleError = useHandleError();
  const { data, isLoading, error } = useCustomFetch(
    endPoint && endPoint,
    params && params
  );
  const columns = [
    {
      accessorKey: "locationName",
      header: "Franchisee",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "club",
    },
    {
      accessorKey: "state",
      header: "State",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "state",
    },
    {
      accessorKey: "totalMembersStartOfMonth",
      header: "Total Members SOM (Active & Hold)",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "totalMembersStartOfMonth",
    },
    {
      accessorKey: "totalMembersEndOfMonth",
      header: "Total Members EOM (Active & Hold)",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "totalMembersEndOfMonth",
    },
    {
      accessorKey: "totalMembersGrowth",
      header: "Total Members Growth Volume",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "totalMembersGrowth",
    },
    // Total DD Members SOM (Active &
    //   Hold)
    {
      accessorKey: "totalDDMembersStartOfMonth",
      header: "Total DD Members SOM (Active & Hold)",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "totalDDMembersStartOfMonth",
    },
    // Total DD Members EOM (Active &
    //   Hold)
    {
      accessorKey: "totalDDMembersEndOfMonth",
      header: "Total DD Members EOM (Active & Hold)",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "totalDDMembersEndOfMonth",
    },
    // DD Member Growth Volume
    {
      accessorKey: "totalDDMembersGrowth",
      header: "DD Member Growth Volume",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "totalDDMembersGrowth",
    },
    {
      accessorKey: "possiblePifRenewals",
      header: "Possible Pif Renewals",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "possiblePifRenewals",
    },

    {
      accessorKey: "actualPifRenewals",
      header: "Actual PIF Renewals Sold",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "actualPifRenewals",
    },
    {
      accessorKey: "pifExpiryNextMonth",
      header: "PIF Expiry Next Month",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "pifExpiryNextMonth",
    },
    {
      accessorKey: "totalDDSales",
      header: "Total DD Sales",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "totalDDSales",
    },
    {
      accessorKey: "totalDDCancelled",
      header: "Total DD Cancelled",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "totalDDCancelled",
    },
    {
      accessorKey: "totalDDCancelledDueToDebt",
      header: "Total DD Cancelled Due To Debt",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "totalDDCancelledDueToDebt",
    },
    {
      accessorKey: "totalDDSuspended",
      header: "DD Suspended (Total at EOM)",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "totalDDSuspended",
    },
    {
      accessorKey: "totalVisits",
      header: "Total Visits",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "totalVisits",
    },
    {
      accessorKey: "dailyAverageVisits",
      header: "Daily Average Visits",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "dailyAverageVisits",
    },

    {
      accessorKey: "weeklyAverageVisitsPerMember",
      header: "Weekly Average Visits Per Member",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "weeklyAverageVisitsPerMember",
    },
    {
      accessorKey: "churn",
      header: "Churn",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "churn",
    },
    {
      accessorKey: "weeklyAverageVisitsPerMember",
      header: "Weekly Average Visits Per Member",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "weeklyAverageVisitsPerMember",
    },
  ];
  const handleChange = (e) => {
    setSearchBarInputVal(e.target.value);
  };
  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };
  const handleGenerate = (values, onSubmitProps) => {
    setGenerate(true);
    setEndPoint(API.snapshotReport);
    let parameters;
    // if (values.chooseDateFrom && values.chooseDateTo) {
    parameters = {
      ...(values.location === "-1" ? {} : { locationId: values.location }),
      // startDate: startDate,
      // endDate: endDate,
      brandId: values.brand,
      startDate: values.chooseDateFrom,
      endDate: values.chooseDateTo,
      // };
    };
    setParams(parameters);
  };
  useEffect(() => {
    if (error && endPoint) {
      setTableData([]);
      handleError(error?.response?.data?.message ?? "Error when fetching api");
    } else if (data) {
      const formattedData = data.data.data.map((item) => {
        item.churn = (Number(item.churn) * 100).toFixed(2) + "%";
        return item;
      });
      setTableData([...formattedData]);
    }
  }, [data, isLoading, error]);

  return (
    <div>
      <ReportGeneratorFormWithMonth
        reportSnapShot
        noTimezone
        brandAndLocation
        noDefaultSelect
        handleSubmit={handleGenerate}
      />
      {isLoading && (
        <div className="report">
          <BeatLoader className="loading" color="white" size={20} />
        </div>
      )}
      {!isLoading && generate && (
        <div className="actions-row report-tables mt-24 mb-12">
          <ExportGroup
            data={tableData ? tableData : []}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            sorting={sorting}
          />
          <div className="search-container">
            <form onSubmit={onSearchBarSubmit}>
              <div className="search-bar">
                <div className="search-bar-container">
                  <input
                    className="fs-10"
                    type="text"
                    name="search"
                    id="search"
                    value={searchBarInputVal}
                    onChange={handleChange}
                    placeholder="Search"
                  />
                  <button type="submit">
                    <AiOutlineSearch />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}{" "}
      {!isLoading && (generate || tableData) && (
        <div className="">
          <Table
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            sorting={sorting}
            setSorting={setSorting}
            columns={columns}
            rowsPerPage={10}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
          />
        </div>
      )}
    </div>
  );
}

export default SnapshotReport;
